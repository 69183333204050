import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 8-8-8\\@75%1RM`}</p>
    <p>{`Band Pull Aparts 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Push Jerk (135/95)`}</p>
    <p>{`Ring Rows`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar will Dawn from Sports Nutrition 2 Go in Cincinnati.
Want to learn the exact number of calories your body burns each day?
Metabolic Testing will be offered before and after the seminar as
well!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      